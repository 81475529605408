/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

/* Scheduler Custom style */
.fc .fc-button-primary {
	--fc-button-bg-color: #056c68;
	--fc-button-hover-bg-color: #045c58;
	--fc-button-active-bg-color: #045c58;
	--fc-button-border-color: #045c58;
	--fc-button-hover-border-color: #045653;
	--fc-button-active-border-color: #045653;
  }

.fc .fc-toolbar .fc-prev-button, [dir] .fc .fc-toolbar .fc-next-button {
  background-color: transparent !important;
  border-color: transparent !important;
  color: #6e6b7b !important;
}

  .fc .fc-button-primary:focus {
	box-shadow: 0 0 0 0.2rem rgba(4, 109, 109, 0.5) !important;
  }
  .fc .fc-datagrid-cell-cushion {
    overflow: hidden;
    padding: 8px;
    white-space: nowrap;
	width: 100% !important;
}
.fc-h-event .fc-event-title {
    display: inline-block;
    left: 0;
    max-width: 100%;
    overflow: hidden;
    right: 0;
    vertical-align: top;
    font-size: 9px;
    font-weight:600;
}

.fc {
  .fc-header-toolbar {
    .fc-toolbar-chunk {
      display: flex !important;
    }
  }
}

@media only screen and (max-width: 992px) {
  .service-search {
    margin-top: 25px !important;
    justify-content: start !important;
    padding-left: 18px !important;
  } 
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  height: 55rem;
  margin-top: 4rem !important;
}

.fc-direction-ltr {
  direction: ltr;
  text-align: left;
  // height: 55rem;
}

.pdfData .fvxlqO {
  background-color: #f3f2f7;
}

.opportunity .select__control{
  width: 120px;
}

.active-order-search{
  position: sticky;
  top: -1px;
  padding: 10px !important;
}

.task-timeline .timeline-event-time{
  min-width: 65px;
}

div[data-column-id="Assigned"] {
  justify-content: end;
  margin-right: 5px;
}

div[data-column-id="target_min"].sc-AxhCb {
  justify-content: center;
  padding-left: 0;
}

div[data-column-id="created"] {
  justify-content: end;
}

div[data-sort-id="created"]{
  padding-right: 0;
}

div[data-column-id="role-contact"].sc-fznKkj {
  justify-content: end;
  padding-right: 20px;
}

.opportunity_id .text-body{
  --bs-text-opacity: null;
}


div[id="cell-5-undefined"]{
  justify-content: end;
}

div[data-column-id="due_date_task"]{
  justify-content: end;
  margin-right: 14px !important;
}

.eDQTws:last-child{
  justify-content: end;
}
.dIuFDs:last-child{
  justify-content: end;
}

#Assigned .fhHjwo {
  margin-right: 12px !important;
}

.fhHjwo:last-child {
  justify-content: end;
  // padding-right: 0 !important;
}

.klgcSr:last-child{
  justify-content: end;
  // margin-right: 12px;
}

.gqexyZ:last-child{
  justify-content: end;
}

.DWosm:last-child{
  justify-content: end;
  margin-right: 12px;
}

.cAwBeE:last-child{
  margin-right: 12px;
  justify-content: end;
}

.content-header .breadcrumb {
   padding-left: 1.2rem !important; 
   margin-bottom: 10px;
}

.font-color .lhVPMq {
  color: #6e6b7b !important;
}

.font-color .ytmTY {
  color: #6e6b7b !important;
}

.we-500 {
  font-weight: 500;
}

.no-drag {
  cursor: pointer !important;
}

.tooltip-inner {
  background-color: white !important;
  box-shadow: 0 0 2px rgb(0 0 0 / 50%) !important;
}

.tooltip-inner {
  color: black !important;
}

.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  border-bottom-color: rgb(0 0 0 / 50%) !important;
}

.d-rag { 
  cursor: grab !important;
}

.d-rag .grab {
  cursor: grab !important;
}

.no-drag .grab {
  cursor: pointer !important;
}

.no-drag {
  cursor: pointer !important;
}

.fc .fc-toolbar-title {
  padding-top: 4px;
  margin-left: 10px !important;
}

.fc .fc-button{
  text-transform: capitalize !important;
}
.taskbold {
  color: black;
}

.no-record .sc-fzqNqU {
  margin-bottom: 25px;
}

[dir] .todo-application .content-area-wrapper .todo-task-list-wrapper .todo-task-list li:hover {
 transform: translateY(-4px);
   box-shadow: 0 3px 10px 0 #ebe9f1;
}

.todo-item {
  .drag-icon {
    visibility: hidden;
    cursor: move;
    position: absolute;
    left: 0.2rem;
    width: 1.75rem;
    height: 4rem;
    padding: 0 5px;
  }
  &:hover {
    .drag-icon {
      visibility: visible;
    }
  }
}

.todo-title-area,
.title-wrapper {
  display: flex;
  align-items: center;
}

.todo-title {
  margin-left: 0.7rem;
  // margin-right: 0.5rem;
}

// html .content.app-content .content-area-wrapper {
//   /* display: flex; */
//   display: flex;
//   position: relative;
//   overflow: hidden;
// }

.dropdown-menu{
  width: max-content !important;
  position: absolute !important;
  inset: 0px 0px auto auto !important;
  transform: translate(0px, 40px) !important
}

.table-height{
  height: 300px;
}

.removebg body {
  background-color: #FFFFFF !important;
}
.removebg blockquote{
  border-left: none !important;
  margin: 0 !important;
  padding: 0 !important;
}

.fqSUoK, .gfkxOp{
  justify-content: start !important;
}

// .qGdZc{
//   justify-content: end !important;
// }

.table-responsive {
   margin-top: 1.3rem !important;
   height: 90vh !important;
}

:fullscreen, ::backdrop {
  background-color: #ffffff;
}

#parentContainer{
  height: 85vh !important;
  overflow-y: scroll;
}

[dir] .btn-sm, [dir] .btn-group-sm > .btn {
  /* padding: 0.486rem 1rem; */
  border-radius: 0.358rem;
}

.generate-size{
  padding: 0.486rem 1rem !important;
}

.total-value{
  // margin-left: 20px !important;
  margin-right: 50px !important;
}

// @media only screen and (max-width: 1500px) {
//   .total-value {
    // margin-right: 80px !important;
  // } 
// }

// @media only screen and (max-width: 1460px) {
//   .total-value-set {
//     margin-left: 1% !important;
//     padding: 0 !important;
//   } 
// }

@media only screen and (max-width: 1250px) {
  .total-value-set {
    margin-left: 5% !important;
    padding: 0 !important;
  } 
}

@media only screen and (max-width: 1180px) {
  .total-value-set {
    margin-left: 5% !important;
    padding: 0 !important;
  } 
}

@media only screen and (min-width: 1600px) {
  .total-value-set {
    margin-left: 10% !important;
  } 
}

[dir] .bs-stepper .bs-stepper-content {
  padding: 0.5rem 1.5rem !important;
}

.padding-row {
  padding: 1rem 0 !important;
}

[dir] .flatpickr-input[readonly], [dir] .flatpickr-input ~ .form-control[readonly], [dir] .flatpickr-human-friendly[readonly] {
  background-color: #ffffff !important;
}

.align-service-quote {
  margin-left: 8px;
}
.day-schedule .fc-theme-standard td, .fc-theme-standard th {
  height: 70px !important;
}

.fc-direction-ltr .fc-timeline-event.fc-event-end, .fc-direction-ltr .fc-timeline-more-link {
  height: 70px !important;
}

.fc .fc-timeline-header-row:last-child .fc-timeline-slot-frame{
  display: flex;
  justify-content: center;
  padding-top: 8px;
}

// [dir] .fc .fc-datagrid-cell-cushion {
//   padding-top: 20px;
// }

[dir=ltr] .fc-h-event .fc-event-title {
  white-space: normal !important;
}

.fc-direction-ltr th.fc-timeline-slot{
  width: 100px !important;
}
.fc-direction-ltr td.fc-timeline-slot{
  width: 50px;
}

.fc-timeline-body .fc-scrollgrid-sync-table {
  min-width: 2400px !important;
}

.table thead th .clientData {
  font-weight: 400 !important;
  color: #6e6b7b !important;
  font-size: 1rem !important;
  text-transform: capitalize; 
}

.add-note-quote {
  padding: 8px 20px;
}

// .notes-box {
//   width: 100%;
//   padding: 15px;
//   border-radius: 5px;
//   border: 1px solid #D8D6DE;
// }

.notes-size {
  min-height: 2.714rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  // padding: 2.5rem 1rem !important;
  width: 100%;
  line-height: 1.6rem;
  // height: 70px;
}
.notes-size-padding {
  padding: 2.5rem 1rem !important;
}
.notes-padding {
  padding: 0.8rem 1rem !important;
}

// .fc-toolbar-chunk .flatpickr-calendar.open {
//   top: 191px !important;
//   right: 6% !important;
//   left: unset !important
// }
// .flatpickr-calendar.open {
//   top: 191px !important;
//   right: 6% !important;
//   left: unset !important
// }
.full-calander {
    background: transparent;
    border: 0;
    outline: 0;
    position: absolute;
    right: 5%;
    // width: 183px;
    margin-top: 4px;
    padding: 5px 0px;
    opacity: 0;
    z-index: 9999999999999999;
}

.right-fullscreen {
  right: 4% !important;
}

.parent-picker {
  .flatpickr-input.flatpickr-mobile {
    opacity: 0;
  }
}

[dir] .flatpickr-calendar .flatpickr-day.inRange, [dir] .flatpickr-calendar .flatpickr-day.inRange:hover {
  background: #056c68 !important;
  border-color: #056c68 !important;
  color: #FFFFFF;
}


[dir=ltr] .flatpickr-calendar .flatpickr-day.inRange, [dir=ltr] .flatpickr-calendar .flatpickr-day.inRange:hover {
  box-shadow: -5px 0 0 #056c68, 5px 0 0 #056c68 !important;
}


.invoice_number{
  border: 1px solid black;
  border-radius: 5px;
  border-color: #e6e6e7;
}

.vJDwz div:first-child, .dMvlRV div:first-child, .cVYwIF div:first-child, .eZrqXo div:first-child, .kNSOXq div:first-child{
  white-space: inherit !important;
}

.spinner-style .spinner-border {
  width: 4rem !important;
  height: 4rem !important;
  color: #045653 !important;
}

.total-position {
  position: relative;
}

.total-value-set {
  position: sticky;
}

// .total-value-calculate{
//   position: absolute;
// }

.total-margin {
  margin-right: 10% !important;
  position: sticky;
}

.set-size-margin{
  margin-right: 4% !important;
}

@media only screen and (max-width: 1250px) {
  .set-size-margin{
    margin-right: 2% !important;
  }
  .total-margin {
    // margin-right: 10% !important;
    position: sticky;
  }
}
div[data-column-id="order_charge"] {
  justify-content: end !important;
}

div[data-column-id="Units_booked"] {
  justify-content: end !important;
}

div[data-column-id="Units_requested"] {
  justify-content: end !important;
}

div[data-column-id="order_total"] {
  justify-content: end !important;
}

div[data-column-id="client_action"] {
  justify-content: end !important;
}

.dropdown-menu[data-popper-placement^=top-] {
  bottom: 0 !important;
}

[dir] .dropdown-menu[data-popper-placement^=top-] {
  transform: translate(27px, -40px) !important;
}

div.dropdown-menu-end {
  padding: 0 !important;
}

.loader-none {
  display: none;
}
.loader-blur {
  position: absolute;
    background: transparent;
    filter: blur(3px);
    height: 100vh;
    width: 100%;
}

@media only screen and (min-width: 1000px) {
  .order_serviceAdd, .order_serviceEdit {
    min-width: 1100px !important;
  }
}
@media only screen and (min-width: 1000px) {
  .Editelement {
    min-width: 998px !important;
  }
}

.order_serviceAdd .modal-content {
  // height: auto !important;
  height: 100vh !important;
}

.order_serviceEdit .modal-content {
  height: 100vh;
}

.edit-tab-content-h {
  // height: 460px !important;
  height: auto !important;
}
.add-tab-content-h {
  // height: 480px !important;
  height: auto;
}

.progressive-bar div[data-column-id="4"] div:first-child {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  max-width: 200px !important;
}

.progressive-bar div[data-column-id="4"] {
  justify-content: end;
}

.project_table div[data-column-id="7"] {
  justify-content: end;
}

.element .disabledbutton {
  pointer-events: none;
  opacity: 0.8;
}

.client-name-width .text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: unset !important;
}

.project-name-width .text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: unset !important;
}

.multi-option [dir=ltr] .select__multi-value, [dir=ltr] .react-select__multi-value {
    margin: 5px 0.7rem 0 0 !important;
  }

.multi-option .css-47z9mc-multiValue {
  background-color: #056c68 !important;
  border-radius: 0.35rem;
  // display: -webkit-box;
  // display: -webkit-flex;
  // display: -ms-flexbox;
  display: flex;
  margin: 5px;
  min-width: 0;
  box-sizing: border-box;
}

// .multi-option .select__value-container {
//   height: 35px !important;
//   overflow-y: scroll !important;
// }
// .css-g1d714-ValueContainer {
//   height: 35px !important;
//   overflow-y: scroll !important;
//   // ::-webkit-scrollbar {
//   //   width: 5px !important;
//   // }
//   // ::-webkit-scrollbar-thumb {
//   //   // background: red; 
//   //   border-radius: 10px !important;
//   // }
// }
// .multi-option .select__value-container::-webkit-scrollbar {
//   width: 5px !important;
// }
// ::-webkit-scrollbar-thumb {
//   // background: red; 
//   border-radius: 10px !important;
// }

.form-check-input:disabled {
  pointer-events: unset !important;
  cursor: not-allowed !important;
}

.form-check-input {
  cursor: pointer !important;
}

.border-style {
  border-style: none !important;
}

.table-link{
  color:#056c68;
}

.public-DraftStyleDefault-block {
  line-height: 5px !important;
}
// .line-height-editor{
//   line-height: 5px !important;
// }

.line-height-editor .DraftEditor-editorContainer span {
  line-height: 20px !important;
}

.line-height-editor .public-DraftStyleDefault-block {
  margin: 2px !important;
}

.card-height-service{
  height: 600px !important;
}

.fc .fc-button {
  font-size: 1.1rem !important;
}

.scroll-height-service{
  height: 100px;
  overflow-y: scroll;
}

@media only screen and (min-width: 1000px) {
  .invoice_order {
    min-width: 1000px !important;
  }
}

@media only screen and (min-width: 1000px) {
  .generate_order {
    min-width: 1000px !important;
  }
}

.invoice_order .table-responsive {
  height: unset !important;
}

.quoteForm .table-responsive {
  height: unset !important;
}

.client_invoice div[data-column-id="6"] {
  justify-content: end;
}
.f_flex{
  display: flex;
justify-content: space-around;
align-items: center;
margin-top: 21px;
margin-bottom: 21px;
}
.f_flex b{
  margin-left: 15px
}
.f_flex div{
  margin-right: 15px
}
.f_flex .m{
  margin-right: 2px
}
.f_flex .margin{
  margin-right: 30px
}
.f_flex .m_client{
  margin-right: 2px
}

.email-attach-file{
  width: 35px;
  position: absolute;
  bottom: -10px;
  opacity: 0;
}

.uploadfile-height{
  height: 100px;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  font: -webkit-small-control;
}
.uploadfile-height ::-webkit-scrollbar-thumb {
 background: red; 
    border-radius: 10px !important;
}
// .invoice-list-dataTable div[data-column-id="4"], div[data-column-id="5"]{
//   justify-content: end;
// }
.pading .owKUf{
  padding-right: 0px !important
}
.pading .eWzKda{
  padding-right: 0px !important
}

 .pading.jxdJuC{
  padding-right: 16px;
}
.pading .fhHjwo{
  padding-right: 0px !important
}


.project-table-display  div[data-column-id="4"] {
  justify-content: start;
}

.pading .kenUPF{
  padding-right: 0px !important
}
.order_table_display  div[data-column-id="4"] {
  justify-content: end;
}
.task_table div[data-column-id="4"]{
  justify-content: start;
}

.task_table div[data-column-id="due_date_task"].fhHjwo {
  justify-content: end;
  
  padding-right: 0px !important
}
.opportunity_dashboard_table div[data-column-id="4"]{
  justify-content: start;
}
.quote_dashbord_table div[data-column-id="4"]{
  justify-content: start;
}

.vertical-layout .header-navbar:not(.navbar-horizontal) .navbar-container ul.navbar-nav li.dropdown.dropdown-user .dropdown-menu, .horizontal-layout .header-navbar:not(.navbar-horizontal) .navbar-container ul.navbar-nav li.dropdown.dropdown-user .dropdown-menu {
  top: 0.5rem !important;
}

@media only screen and (max-width: 1500px) {
  .padding-zero{
    padding: 6px 0rem !important;
  }
}


.client_aline .aline_right{
  text-align: right;
}

.text_align{
  text-align: end;
}

.invoice_table {
  div[data-column-id="4"] {
    justify-content: end;
  }
  div[data-column-id="5"] {
    justify-content: end;
  }
  div[data-column-id="6"] {
    justify-content: end;
  }
  div[data-column-id="7"] {
    justify-content: end;
  }
 
}

.serviceItem {
  div[data-column-id="5"] {
    justify-content: end;
  }
  div[data-column-id="7"] {
    justify-content: end;
  }
  div[data-column-id="7"] div {
    white-space: unset !important;
  }
  div[data-column-id="6"] div {
    white-space: unset !important;
  }
}

.box_order{
  background-color: #efefef;
  min-height: 147px;
  border: 1px solid #d8d6de;
  border-radius: 5px;
} 

.client-order-table div[data-column-id="4"] {
  justify-content: end;
}

.client-order-table div[data-column-id="5"] {
  justify-content: end;
}

.img-title-show {
    width: 120px; 
    height: 23px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    white-space: nowrap;
    margin-top: 5px;
    // border: 1px solid;
    // padding: 0px 0 0 5px;
}
.ScheduleData {
  background-color: #056c68;
  border:'1px solid #056c68'
}

.blockoutData {
  background-color: #ff6347;
  border:'1px solid #ff6347'
}
.unit{
  width: 18%;
}

.unique .content-header .breadcrumb {
   margin-bottom: 10px; 
   padding-top: 0px;
}

.scrollbar::-webkit-scrollbar{ 
  width :14px;
 } 
.servicemodelbody{
  padding: 0.01rem 1.001rem !important;
}
.servicenotes{
  min-height: 2.714rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  // padding: 2.5rem 1rem !important;
  width: 100%;
  line-height: 1.6rem;
  height: 70px;
}

.report_table thead {
  background-color: #efefef;
  position: sticky; 
  top: -1px;
  z-index: 1;
}

// .opportunities_table div[data-column-id="4"] {
//   justify-content: end;
// }

.margin_add {
  margin-bottom: 5px;
}

.quotes_table  div[data-column-id="4"] {
  justify-content: center;
}

.servicedelivery_plus {
  background-color: white;
  color: red;
  border-radius: 50%;
  margin-left: 5px;
} 

.sigCanvas {
  width: 345px !important;
  height: 120px !important;
}

.scroller {
  display : flex;
  justify-content : center;
}

.collapse_table .table-responsive {
  height: unset !important;
  max-height: 300px !important;
  overflow-y: scroll;
  margin-top: unset !important;
  border-bottom: 1px solid #e0e0e0;
}

.site-contact-table {
  height: calc(450px - 262px);
  overflow: auto;
}

.disabled-link {
  cursor : not-allowed !important;
  // opacity : 50%;
}

.resources-container {
  border: 1px solid #92929230;
  border-radius: 5px;
  padding: 5px;
  height: auto;
  max-height: 52px;
  overflow-x: auto;
  overflow-y: hidden;
}

.accordion-item {
  .task {
    .accordion-button {
      padding-left : 0;
      padding-right: 0;
    }
  }
}

.row-border {
  border: 1px solid #92929250;
}

.element-border-bottom {
  border-bottom: 1px solid #d8d6de;
}

.w-20 {
  width: 20%;
}
.notes{
    width:80px;
    // margin: 20px;
    line-height: 1em; 
    font-weight: 400;
    text-overflow: ellipsis;
    margin-bottom: 12px;
    cursor: pointer;
    word-break: break-all;
    overflow:hidden;
    white-space: nowrap;
    position: relative;

}

// .invoice_service_status {  
//   display: flex;
//   // justify-content: end;
// }
// .invoice_service_status:last-child {
//   display: flex;
//   justify-content: end;
// }

.statusWork_item{
  color:"black";
  width: "150px";
  background-color: "#f8f8f8" !important;
  // backgroung-color: "#f8f8f8"
}
// .abcd{
//   width: 400px;
//   height: 200px;
// }

.select_status_mui .MuiSelect-select {
  padding: 7px !important;
}

.select_status_mui_label{
  top:-8px !important
}

.select_status_mui_main{
  border-radius: 5px;
}
.MuiPaper-elevation ul{
  padding: 0;
}
.select_status{
  color: #6e6b7b;
  font-family: "Montserrat", Helvetica, Arial, serif;
}
.MuiPaper-elevation ul .MuiSvgIcon-fontSizeMedium {
  color: #056c68;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
  background-color: rgba(101, 184, 148, 0.08);
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected:hover {
  background-color: rgba(25, 180, 97, 0.12);
}

.service-delivery-modal {
  max-width: 1400px;
  margin: auto;
}

.invoice-head-col {
  padding-left: 5px;
}

.client_opportunity_table div[data-column-id="4"] {
  justify-content: end;
}

.project_opportunity_table div[data-column-id="4"] {
  justify-content: end;
}

.invoice_workitem_table div[data-column-id="3"] {
  justify-content: start;
}

.test-label {
  display: flex;
  justify-content: flex-end;
}

.font-style-change{
  font-family: "Montserrat", Helvetica, Arial, serif;
  color: rgba(0,0,0,0.87);
}

.font-style-change-total{
  font-family: "Montserrat", Helvetica, Arial, serif;
  color: rgba(0,0,0,0.87);
  font-weight: 400;
}

.add-notes-attach-file{
  width: 118px;
  position: absolute;
  bottom: 0px;
  opacity: 0;
}

.margin-top-invoice{
  margin-top: 0.1rem !important;
}